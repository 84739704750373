import React from "react";
import { CCard,CCardBody,CCardText,CCardImage,CCol } from "@coreui/react";
import investigador1 from '../info/investigador1.json';
import estrada from '../image/equipo/estrada.jpg';

function Investigador1(){

    return(
        <section  style={{ display: "flex",marginRight:"50px" }}>
            <CCol xs="xs" >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "50vh" , paddingLeft:"500px" }}>
                    <CCard style={{ borderRadius: "40px", height: "50vh", width: "300px", position: "fixed"}}>
                        <CCardImage orientation="top" src={estrada} style={{ borderRadius: "40px", padding: "20px", height: "80%" }} />
                        <CCardBody>
                        <CCardText>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <a href="https://orcid.org/0000-0003-4337-6887" className="btn " style={{ borderRadius: "40px", marginTop: "20px" ,background:"#0065B3" ,color:"white" }}>Ver CTI VITAE</a>
                            </div>
                        </CCardText>
                        </CCardBody>
                    </CCard>
                </div>
            </CCol> 
            <CCol style={{ marginLeft: "250px",position:"relative", zIndex:1 }}>
                {investigador1.map(item => (
                    <div style={{ textAlign: "center", marginTop: "50px" }}>
                        <h1>{item.nombre}</h1>
                        <p>{item.cargo}</p>
                    
                    <div style={{ marginTop: "100px", marginLeft: "30px" }}>
                        <h1> Reseña</h1>
                        <p>{item.acercade}</p>
                    </div>
                    </div>
                ))}
            </CCol>
            
        </section>
    )
}
export default Investigador1;