import React from "react";
import {
    CNavbar,
    CNavbarBrand,
    CNavbarNav,
    CNavLink,
    CNavbarToggler,
    CNavItem,
} from '@coreui/react';
import { CContainer } from '@coreui/react';
import { CCollapse } from '@coreui/react';
import { useState } from "react";
import logoImage from '../image/logo/logo.jpg';


function NavBar() {


    const [visible, setVisible] = useState(false)

    return (

        <div style={{ position:"fixed",top:"0", width:"100%" ,height:"30px" , zIndex: 100 }} >
            <CNavbar expand="lg" colorScheme="ligth" style={{ backgroundColor: '#0065B3' }}>
                <CContainer fluid >
                    <CNavbarBrand href="/">
                        <img src={logoImage} width="128" height="60" style={{ marginLeft: '40px' }} />
                    </CNavbarBrand>
                    <CNavbarToggler onClick={() => setVisible(!visible)} />
                    <CCollapse className="navbar-collapse justify-content-center" visible={visible} style={{ marginLeft: '50px' }}>
                        <CNavbarNav>
                            <CNavItem>
                                <CNavLink href="/" active className="text-white" style={{ marginRight: '20px' }}>
                                    Acerca de
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>

                                <CNavLink href="/produccion" className="text-white" style={{ marginRight: '20px' }}>
                                    Producción Científica
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>

                                <CNavLink href="/areas" className="text-white" style={{ marginRight: '20px' }}>
                                    Áreas De Investigación
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>

                                <CNavLink href="/equipo" active className="text-white">
                                    Equipo y Colaboradores
                                </CNavLink>
                            </CNavItem>
                        </CNavbarNav>
                    </CCollapse>
                    
                </CContainer>
            </CNavbar>
        </div>
    );
}
export default NavBar;
