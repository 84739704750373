import React from "react";
import { CCard,CCardBody,CCardTitle,CCardText,CCardImage,CCardImageOverlay } from "@coreui/react";
import { useRef } from "react";
import fila from'../image/biometfila/arcobiomet.jpeg';
import './AcercaDe.css';

function Proyectos(){
    
    const year2023Ref = useRef(null);
{/*}
    const scrollToYear = (yearRef) => {
        yearRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      };*/}
    return (
        <div>
            <section id="menu-publicaciones">
                <h1 style={{ textAlign: "center" }}> Proyectos de Investigación</h1>
            </section>
            <section id="publicaciones-2022"  style={{marginTop:"50px"}}>
                <div id="2023" ref={year2023Ref} >
                   
                    <CCard  style={{marginLeft:"80px",marginTop:"40px",marginBottom:"40px",maxWidth:"1700px"}}>
                        <CCardBody >
                            <CCardTitle>
                                Remoción sustentable de contaminantes farmacéuticos emergentes en sistemas acuosos usando novedosas matrices biopoliméricas de quitina/quitosano: ácido poliláctico-quitina en 3d y quitosano funcionalizado con óxido de grafeno.
                            </CCardTitle>
                            <CCardText>
                                Proyecto del Vicerrectorado de Investigación 
                            </CCardText>
                        </CCardBody>    
                    </CCard>
                    <CCard  style={{marginLeft:"80px",marginTop:"40px",marginBottom:"40px",maxWidth:"1700px"}}>
                        <CCardBody >
                            <CCardTitle>
                            Fabricación eco amigable y de bajo costo basado en nanofibras de celulosa a partir de la coronta de maíz (Zea mays L.) para la remoción de metales pesados y contaminantes orgánicos: evaluación como biorremediador de ambientes acuáticos. 
                            </CCardTitle>
                            <CCardText>
                                Proyecto del Vicerrectorado de Investigación 
                            </CCardText>
                        </CCardBody>    
                    </CCard>
                    <CCard  style={{marginLeft:"80px",marginTop:"40px",marginBottom:"40px",maxWidth:"1700px"}}>
                        <CCardBody >
                            <CCardTitle>
                            Una via sustentable del cangreso cancer porteri: preparacion de un bioadsorbente quitosano/magnetita-grafeno para la remediacion de ambientes acuaticos contaminados por metales pesados y su evaluacion con bioindicadores.
                            </CCardTitle>
                            <CCardText>
                                Proyecto del Vicerrectorado de Investigación 
                            </CCardText>
                        </CCardBody>    
                    </CCard>
                    <CCard  style={{marginLeft:"80px",marginTop:"40px",marginBottom:"40px",maxWidth:"1700px"}}>
                        <CCardBody >
                            <CCardTitle>
                            Fabricación y caracterización de películas nanoestructuradas 2D de óxido de hierro sobre óxido de cobre (CuO/Fe2O3). Aplicación en remoción de arsénico y estudio de su ecotoxicidad en sistemas acuáticos a través de bioindicadores
                            </CCardTitle>
                            <CCardText>
                                Proyecto del Vicerrectorado de Investigación 
                            </CCardText>
                        </CCardBody>    
                    </CCard>
                    <CCard  style={{marginLeft:"80px",marginTop:"40px",marginBottom:"40px",maxWidth:"1700px"}}>
                        <CCardBody >
                            <CCardTitle>
                            Propuesta de un sistema de adquisición de datos para la evaluación de la concentración de ozono estratosférico mediante sensores electrónicos y biológico Allium cepa, protegido en una góndola tipo Cubesat 2U a 2.7U con estudios estructurales, térmicos, vibracionales y de radiación en el departamento de ICA 2023” 
                            </CCardTitle>
                            <CCardText>
                                Tesis  
                            </CCardText>
                        </CCardBody>    
                    </CCard>
                    <CCard  style={{marginLeft:"80px",marginTop:"40px",marginBottom:"40px",maxWidth:"1700px"}}>
                        <CCardBody >
                            <CCardTitle>
                            “Desarrollo de matrices biopoliméricas funcionalizadas (Qt-F-PLA) mediante impresora 3D a partir de filamento de ácido poliláctico (PLA) y quitina (Qt), como material antimicrobiano (Ferulas Distales) para la manipulación y esterilización de dispositivos médicos.” 
                            </CCardTitle>
                            <CCardText>
                                Tesis 
                            </CCardText>
                        </CCardBody>    
                    </CCard>
                    <CCard  style={{marginLeft:"80px",marginTop:"40px",marginBottom:"40px",maxWidth:"1700px"}}>
                        <CCardBody >
                            <CCardTitle>
                            Nuevo ligando quitosano-DFO para la detección selectiva de iones fe(III): un sistema potencial para tratar enfermedades por desregulación de hierro. 
                            </CCardTitle>
                            <CCardText>
                                Tesis
                            </CCardText>
                        </CCardBody>    
                    </CCard>
                    <CCard  style={{marginLeft:"80px",marginTop:"40px",marginBottom:"40px",maxWidth:"1700px"}}>
                        <CCardBody >
                            <CCardTitle>
                            “Modelado dinámico para mejorar el control de un vehículo submarino (AUV) en el monitoreo de la calidad del agua de mar luego de un derrame de petróleo utilizando bioindicador Artemia Salina” 
                            </CCardTitle>
                            <CCardText>
                                Proyecto de Tesis 
                            </CCardText>
                        </CCardBody>    
                    </CCard>
                    <CCard  style={{marginLeft:"80px",marginTop:"40px",marginBottom:"40px",maxWidth:"1700px"}}>
                        <CCardBody >
                            <CCardTitle>
                            Propuesta del sistema PID “MAGIKARP” para disminuir la contaminación por derrame de petróleo a través del diseño y construcción del dron marino “GYARADOS”, en la Universidad Nacional de Ingeniería, 2023. 
                            </CCardTitle>
                            <CCardText>
                                Proyecto de Tesis 
                            </CCardText>
                        </CCardBody>    
                    </CCard>
                    <CCard  style={{marginLeft:"80px",marginTop:"40px",marginBottom:"40px",maxWidth:"1700px"}}>
                        <CCardBody >
                            <CCardTitle>
                            Diseño y construcción de un sistema de respiración microbiana lined  para evaluar la biorremediación de suelos de playa contaminados de petróleo a partir de hongo aspergillius f. Y residuos de caparazón de cangrejo 
                            </CCardTitle>
                            <CCardText>
                                Proyecto de Tesis 
                            </CCardText>
                        </CCardBody>    
                    </CCard>
                    <CCard  style={{marginLeft:"80px",marginTop:"40px",marginBottom:"40px",maxWidth:"1700px"}}>
                        <CCardBody >
                            <CCardTitle>
                            Microesferas de compósito de quitosano modificado con ácido tiobarbitúrico (Quit-Nsuc-Bar) para la remoción sustentable de tetraciclina como contaminante farmacéutico emergente en sistemas acuosos 
                            </CCardTitle>
                            <CCardText>
                                Proyecto de Tesis 
                            </CCardText>
                        </CCardBody>    
                    </CCard>
                    <CCard  style={{marginLeft:"80px",marginTop:"40px",marginBottom:"40px",maxWidth:"1700px"}}>
                        <CCardBody >
                            <CCardTitle>
                            “Automatización del conteo de Artemia Salina en estagio I-III y cistos con visión artificial asistido por un posicionador bidimensional controlado desde una aplicación web” 
                            </CCardTitle>
                            <CCardText>
                                Proyecto de Tesis
                            </CCardText>
                        </CCardBody>    
                    </CCard>
                </div>
            </section>
            <section style={{padding:"100px"}} className="row justify-content-md-center">
                <CCard  style={{borderRadius:"50px", width:"900px",padding:"0px"}}>
                    <div style={{ position: "relative", borderRadius: "40px", overflow: "hidden" }}>
                        <CCardImage src={fila}  style={{objectFit:"cover",borderRadius:"40px" ,with:"600px",height:"400px"}}/>
                        <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, background: "rgba(0, 101, 179, 0.5)" }}></div>
                        <CCardImageOverlay style={{borderRadius:"40px" ,with:"900px"}} >
                            <CCardTitle style={{color:"#fff",fontSize:"30px",textAlign:"center",marginTop:"240px"}}>Conoce Nuestras Áreas de Investigación </CCardTitle>
                            <CCardText className="ccard-text" style={{textAlign:"center",color:"#fff"}}>
                                Nuestras producción científica involucran diversas carreras trabajando en conjunto para lograr un objetivo comun.
                            </CCardText>
                            <CCardText style={{textAlign:"center",marginBottom:"20px"}} >
                                <a href="/produccion" class="btn produc-cienti" style={{ paddingLeft: "40px", paddingRight: "40px", borderRadius: "30px",background:"#0065B3" ,color:"white" }}> Areas de Investigacion</a>
                            </CCardText>
                        </CCardImageOverlay>
                    </div>
                </CCard>
            </section>
        </div>
    )
}
export default Proyectos;