import React from 'react'
import { CButton, CCard, CCardBody, CCardTitle, CCardText,CCardImage,CCardImageOverlay } from '@coreui/react';
import { useRef } from 'react';
import fila from '../image/biometfila/arcobiomet.jpeg';
import './AcercaDe.css';
function Publicaciones(){

    const year2022Ref = useRef(null);
    const year2021Ref = useRef(null);
    const year2020Ref = useRef(null);
    const year2019Ref = useRef(null);
    const year2018Ref = useRef(null);
    const year2017Ref = useRef(null);
    const year2015Ref = useRef(null);



    const scrollToYear = (yearRef) => {
        yearRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      };
    return (
        <div>
            <section id="menu-publicaciones">
                <h1 style={{ textAlign: "center" }}> Publicaciones </h1>
                <div className="d-flex justify-content-center" style={{marginTop:"40px"}}>
                    <div className="btn" role="group">
                        <CButton  onClick={() => scrollToYear(year2022Ref)} style={{borderColor:"#0065B3",background:"#0065B3", margin: "0 20px",borderRadius:"20px"}}>2022</CButton>
                        <CButton  onClick={() => scrollToYear(year2021Ref)} style={{borderColor:"#0065B3",background:"#0065B3", margin: "0 20px",borderRadius:"20px"}}>2021</CButton>
                        <CButton  onClick={() => scrollToYear(year2020Ref)} style={{borderColor:"#0065B3",background:"#0065B3", margin: "0 20px",borderRadius:"20px"}}>2020</CButton>
                        <CButton  onClick={() => scrollToYear(year2019Ref)} style={{borderColor:"#0065B3",background:"#0065B3", margin: "0 20px",borderRadius:"20px"}}>2019</CButton>
                        <CButton  onClick={() => scrollToYear(year2018Ref)} style={{borderColor:"#0065B3",background:"#0065B3", margin: "0 20px",borderRadius:"20px"}}>2018</CButton>
                        <CButton  onClick={() => scrollToYear(year2017Ref)} style={{borderColor:"#0065B3",background:"#0065B3", margin: "0 20px",borderRadius:"20px"}}>2017</CButton>
                        <CButton  onClick={() => scrollToYear(year2015Ref)} style={{borderColor:"#0065B3",background:"#0065B3", margin: "0 20px",borderRadius:"20px"}}>2015</CButton>
                    </div>
                </div>
            </section>
            <section id="publicaciones-2022"  style={{marginTop:"50px"}}>
                <div id="2022" ref={year2022Ref} >
                    <h1 style={{marginLeft:"80px"}}>2022</h1>
                    <CCard  style={{marginLeft:"80px",marginTop:"40px",marginBottom:"40px"}}>
                        <CCardBody >
                            <CCardTitle>
                                "Design, development, and implementation of 3d-printed polylactic acid centrifuge rotors for laboratory-scale applications".
                            </CCardTitle>
                            <CCardText>
                                Moreno,L; Vasquez,A; Pastrana,E & Pastrana,R.(2022)
                            </CCardText>
                            <CCardText>
                                Quimica Nova.https://doi.org/10.21577/0100-4042.20170936
                            </CCardText>
                        </CCardBody>    
                    </CCard>
                </div>
                <div id="2021" ref={year2021Ref}>
                    <h1 style={{marginLeft:"80px"}}>2021</h1>
                    <CCard  style={{marginLeft:"80px",marginTop:"40px"}}>
                        <CCardBody >
                            <CCardTitle>
                                "Workshop como uma metodologia para o ensino-aprendizagem de ciencias:um estudio de caso com o grupo Bioinorganic chemestry Environment and Medicine (BIOMET)".
                            </CCardTitle>
                            <CCardText>
                                Vitorino,H.A; Alta,R.Y.P & Ortega, P. (2021) 
                            </CCardText>
                            <CCardText>
                                Pesquisa E Ensino Em Ciencias Exatas E Da Natureza, 5, 1699. https://doi.org/10.29215/pecen.v5i0.1699
                            </CCardText>
                        </CCardBody>    
                    </CCard>
                    <CCard   style={{marginLeft:"80px",marginTop:"40px"}}>
                        <CCardBody >
                            <CCardTitle>
                                "Short exposure of Artemia Salina to group-12 metals:Comparing hatchability, mortality, lipid peroxidation, and swimming speed".
                            </CCardTitle>
                            <CCardText>
                                Giuliana K.Ñañez P ; Nelson S. Sanabrio M ; Roxana Y. Pastrana Alta, Hector Aguilar V. (2021)  
                            </CCardText>
                            <CCardText>
                                Ecotoxicology and Environmental Safety. https://doi.org/10.1016/j.ecoenv.2021.112052
                            </CCardText>
                        </CCardBody>    
                    </CCard>
                    <CCard  style={{marginLeft:"80px",marginTop:"40px"}}>
                        <CCardBody >
                            <CCardTitle>
                                "Designing and Fabrication of a Low-Cost Dip - Coater for Rapid Production of Uniform thin Films".
                            </CCardTitle>
                            <CCardText>
                                David A.Castillo ; Steveen J.Loarte; Arturo A.Fernandez; Elizabeth C.Pastrana, and Roxana Y.Pastrana (2021)
                            </CCardText>
                            <CCardText>
                                Quimica Nova, https://dx.doi.org/10.21577/0100-4042.20170805
                            </CCardText>
                        </CCardBody>    
                    </CCard>
                    <CCard  style={{marginLeft:"80px",marginTop:"40px",marginBottom:"40px"}}>
                        <CCardBody >
                            <CCardTitle>
                                "Fabrication and characterization of copper( II ) oxide/iron( III ) oxide thin film heterostructures for trace arsenic (III) removal in water".
                            </CCardTitle>
                            <CCardText>
                                Elizabeth C.Pastrana; Steveen J.Loarte ;Carlos D.Gonzales ; Roxana Y.Pastrana Alta, Hugo A.Alarcón.(2021)
                            </CCardText>
                            <CCardText>
                                https://doi.org/10.1016/j.tsf.2020.13.8440
                            </CCardText>
                        </CCardBody>    
                    </CCard>
                </div>
                <div id="2020" ref={year2020Ref}>
                    <h1 style={{marginLeft:"80px"}}>2020</h1>
                    <CCard  style={{marginLeft:"80px",marginTop:"40px",marginBottom:"40px"}}>
                        <CCardBody >
                            <CCardTitle>
                                "Minimizing Organic Waste Generated by Pineapple Crown: A Simple Process to Obtain Cellulose for the Preparation of Recyclable Containers".
                            </CCardTitle>
                            <CCardText>
                                Diana Choquecahua M. ; Kristy Stefany Otero N. ; Efrén Eugenio Chaparro M. ; Dora Amalia Mayta H. ; Roxana Yesenia Pastrana and Hector Aguilar V.(2020)
                            </CCardText>
                            <CCardText>
                                Recycling ; https://doi.org/10.3390/recycling5040024
                            </CCardText>
                        </CCardBody>    
                    </CCard>
                </div>
                <div id="2019" ref={year2019Ref}>
                    <h1 style={{marginLeft:"80px"}}>2019</h1>
                    <CCard   style={{marginLeft:"80px",marginTop:"40px",marginBottom:"40px"}}>
                        <CCardBody >
                            <CCardTitle>
                                "Lipid Peroxidation in Hepatopancreas, Gill, and Hemolymph of Male and Female Crabs Platyxanthus orbignyi after Air Exposure".
                            </CCardTitle>
                            <CCardText>
                                Hector Aguilar Vitorino; Roxana Yesenia Pastrana Alta and Priscila Ortega.(2019)
                            </CCardText>
                            <CCardText>
                                Journal Marine Sciencie and Enginering; https://doi.org/10.3390/jmse7100347
                            </CCardText>
                        </CCardBody>    
                    </CCard>
                </div>
                <div id="2018" ref={year2018Ref}>
                    <h1 style={{marginLeft:"80px"}}>2018</h1>
                    <CCard   style={{marginLeft:"80px",marginTop:"40px"}}>
                        <CCardBody >
                            <CCardTitle>
                                "Evaluation of iron loading in four types of hepatopancreatic cells of the mangrove crab Ucides cordatus using ferrocene derivatives and iron supplements".
                            </CCardTitle>
                            <CCardText>
                                Hector Aguilar Vitorino; Priscila Ortega; Roxana Y.Pastrana Alta; Flavia Pinheiro Zanotto; Breno Pannia Espósito.(2018)
                            </CCardText>
                            <CCardText>
                                Environmental Science and Pollution Research; https://doi.org/10.1007/s11356-018-1819-3
                            </CCardText>
                        </CCardBody>    
                    </CCard>
                    <CCard   style={{marginLeft:"80px",marginTop:"40px",marginBottom:"40px"}}>
                        <CCardBody >
                            <CCardTitle>
                                "Magnetite nanoparticles coated with oleic acid: accumulation in hepatopancreatic cells of the mangrove crab Ucides cordatus".
                            </CCardTitle>
                            <CCardText>
                                Hector Aguilar Vitorino; Priscila Ortega; Roxana Y.Pastrana Alta; Flavia Pinheiro Zanotto; Breno Pannia Espósito.(2018)
                            </CCardText>
                            <CCardText>
                                Environmental Science and Pollution Research (2018) 25:35672-35681; https://doi.org/10.1007/s11356-018-3480-2
                            </CCardText>
                        </CCardBody>    
                    </CCard>
                </div>
                <div id="2017" ref={year2017Ref}>
                    <h1 style={{marginLeft:"80px"}}>2017</h1>
                    <CCard   style={{marginLeft:"80px",marginTop:"40px"}}>
                        <CCardBody >
                            <CCardTitle>
                                "Hepatopancreatic cells of a stone crab Menippe frontalis from Perú:separation, viability study, and evaluation of lipoperoxidation against cadmium contamination".
                            </CCardTitle>
                            <CCardText>
                                H.A.Vitorino; R.Y.Pastrana.Alta; E.C.P.alta; P.Ortega(2017)
                            </CCardText>
                            <CCardText>
                                In Vitro Cellular & Developmental Biology - Animal. DOI 10.1007/s11626-017-0168-8
                            </CCardText>
                        </CCardBody>    
                    </CCard>
                    <CCard   style={{marginLeft:"80px",marginTop:"40px"}}>
                        <CCardBody >
                            <CCardTitle>
                                "Triphenylphosphonium-desferrioxamine as a candidate mitochondrial iron chelator".
                            </CCardTitle>
                            <CCardText>
                                Roxana Y.P.Alta; Hector A.Vitorino; Dibakar Goswami; Cleber W.Liria,Simon P.Wisnovsky,Shana O Kelley, M.Teresa Machini; Breno P.Espósito (2017)
                            </CCardText>
                            <CCardText>
                                Biometals.DOI 10.1007/s10534-017-0039-5
                            </CCardText>
                        </CCardBody>    
                    </CCard>
                    <CCard   style={{marginLeft:"80px",marginTop:"40px",marginBottom:"40px"}}>
                        <CCardBody >
                            <CCardTitle>
                                "Mitochondria-penetrating peptides conjugated to desferrioxamine as chelators for mitochondrial labile iron".
                            </CCardTitle>
                            <CCardText>
                                Roxana Y.P.Alta; Hector A.Vitorino; Dibakar Goswami; Cleber W.Liria,Simon P.Wisnovsky,Shana O Kelley, M.Teresa Machini; Breno P.Espósito (2017)
                            </CCardText>
                            <CCardText>
                                PLOS ONE,https://doi.org/10.1371/journal.pone.0171729
                            </CCardText>
                        </CCardBody>    
                    </CCard>
                </div>
                <div id="2015" ref={year2015Ref}>
                    <h1 style={{marginLeft:"80px"}}>2015</h1>
                    <CCard   style={{marginLeft:"80px",marginTop:"40px",marginBottom:"40px"}}>
                        <CCardBody >
                            <CCardTitle>
                                "Deferasirox-TAT(47-57) peptide conjugate as a water soluble, bifunctional iron chelator with potential use in neuromedicine".
                            </CCardTitle>
                            <CCardText>
                                Dibakar Goswami; Hector A.Vitorino; Roxana Y.P.Alta; Daniel M.Silvestre; Cassiana S.Nomura; M.Teresa Machini & Breno P.Espósito (2015).
                            </CCardText>
                            <CCardText>
                                BioMetals; DOI 10.1007/s10534-015-9873-5
                            </CCardText>
                        </CCardBody>    
                    </CCard>
                </div>
            </section>
            <section style={{padding:"100px"}} className="row justify-content-md-center">
                <CCard  style={{borderRadius:"50px", width:"900px",padding:"0px"}}>
                    <div style={{ position: "relative", borderRadius: "40px", overflow: "hidden" }}>
                        <CCardImage src={fila}  style={{objectFit:"cover",borderRadius:"40px" ,with:"600px",height:"400px"}}/>
                        <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, background: "rgba(0, 101, 179, 0.5)" }}></div>
                        <CCardImageOverlay style={{borderRadius:"40px" ,with:"900px"}} >
                            <CCardTitle style={{color:"#fff",fontSize:"30px",textAlign:"center",marginTop:"240px"}}>Conoce Nuestras Áreas de Investigación </CCardTitle>
                            <CCardText className="ccard-text" style={{textAlign:"center",color:"#fff"}}>
                                Nuestras producción científica involucran diversas carreras trabajando en conjunto para lograr un objetivo comun.
                            </CCardText>
                            <CCardText style={{textAlign:"center",marginBottom:"20px"}} >
                                <a href="/produccion" class="btn produc-cienti" style={{ paddingLeft: "40px", paddingRight: "40px", borderRadius: "30px",background:"#0065B3" ,color:"white" }}> Areas de Investigacion</a>
                            </CCardText>
                        </CCardImageOverlay>
                    </div>
                </CCard>
            </section>
        </div>
    )
}
export default Publicaciones; 