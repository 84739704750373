import React from "react";
import { CImage } from "@coreui/react";
import { CCarousel, CCarouselItem, CCarouselCaption } from "@coreui/react";
import { CCard, CCardBody, CCardTitle, CCardText, CCardImage, CCardImageOverlay } from "@coreui/react";
import { CCol, CRow} from "@coreui/react";
import misionImage from '../image/mision/MISION1.jpg';
import visioImage from '../image/vision/vision.jpg';
import valoresImage from '../image/valores/Valores.jpeg';
import group1 from '../image/grupoBiomet/ALBERTA-DARI.jpg';
import group2 from '../image/grupoBiomet/Diadelamujerylaninaenlaciencia.jpg';
import group3 from '../image/grupoBiomet/Diadelamujerylaninaenlaciencia1.jpg';
import group4 from '../image/grupoBiomet/ExpoFeria2022.jpg';
import group5 from '../image/grupoBiomet/VRI ESPECIAL UNI .jpg';
import group6 from '../image/grupoBiomet/EXPOFERIA-SHAKI.jpg';
import group7 from '../image/grupoBiomet/Expoferia-ANTENA.jpg';
import fila from '../image/biometfila/arcobiomet.jpeg';
import './AcercaDe.css';

function AcercaDe() {
    const acercade = "Nuestra investigación se centra en lograr avances que beneficien a la sociedad.Nuestro grupo de investigación brinda una oportunidad de capacitación altamente" +
        "multidisciplinaria y estimulante para la próxima generación de científicos. Los proyectos en curso se centran en el desarrollo de nuevas plataformas analíticas" +
        "e involucran aspectos de diversas disciplinas que van desde la química de materiales hasta su ecotixicologia y mitigacion climatica  a travez de la tecnología."

    const mision = "Somos una institución de investigación especial que atiende a estudiantes y colaboradores de todo el territorio nacional y desarrolla proyectos, publicaciones y patentes de un alto factor" +
        " de impacto, la formación de estudiantes de pre-grado y posgrado es integral de calidad, con énfasis en la investigación e innovación Biotecnológica, valorando sus particularidades y fomentando su " +
        "compromiso por alcanzar el máximo potencial y contribuir con el desarrollo de la sociedad.";

    const vision = "Ser un Centro de Investigación, Desarrollo, Educación y Servicio  referente a nivel nacional, reconocido en el año 2028 por desarrollar el máximo potencial de nuestros estudiantes y  " +
        " colaboradores a través de nuestras líneas de investigación, quienes serán capaces de emprender para enfrentar con éxito los retos del mundo contemporáneo, comprometidos con el desarrollo de una sociedad sostenible.";

    const valores = (
        <div>
            <strong>1.Respeto:</strong> En nuestra institución tratamos con dignidad y consideración a todas las personas, al mundo que nos rodea y a nosotros mismos.
            <br />
            <strong>2.Integridad:</strong> En el grupo de investigación BIOMET somos honestos, justos y confiables. Actuamos siempre con fundamento en la verdad.
            <br />
            <strong>3.Empatía:</strong> En el grupo de investigación BIOMET siempre nos esforzamos por entender, comprender y ayudar a nuestros semejantes.
            <br />
            <strong>4.Investigación e Innovación</strong> En el grupo de investigación BIOMET la actividad educativa está orientada a que los estudiantes potencien sus capacidades de conocer, transformar y aplicar sus aprendizajes significativos.
            <br />
            <strong>5.Excelencia</strong> En el grupo de investigación BIOMET, nos esforzamos por superarnos cada día, asumiendo nuevos retos para crecer y alcanzar el éxito.
        </div>
    );
    return (
        
        <div>
            <section>
                <div style={{ maxWidth: "1450px", margin: "0 auto" }}>
                    <h1 style={{ textAlign: "center", marginTop: "55px", color: "#0065B3", fontSize: "40px", fontWeight: "bold" }}> ACERCA DE  </h1>
                    <p style={{ marginLeft: "80px", marginRight: "100px", marginTop: "50px", textAlign: "justify" }}>
                        {acercade}
                    </p>
                </div>

                <div className="row justify-content-md-center">
                    <CCard className="mb-3" style={{ maxWidth: '1320px', marginTop: "65px", borderRadius: "40px", background: "#F9FAFF" }}>
                        <CRow className="g-3" >
                            <CCol md={6}>
                                <CCardImage src={misionImage} style={{ padding: '20px', objectFit: 'cover', maxHeight: '400px', borderRadius: "40px" }} />
                            </CCol>
                            <CCol md={6} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <CCardBody>
                                    <CCardTitle style={{ textAlign: "center", marginBottom: "25px", marginTop: "50px" }}> Misión </CCardTitle>
                                    <CCardText style={{ textAlign: "justify", margin: "0 auto", }}>
                                        {mision}
                                    </CCardText>
                                </CCardBody>
                            </CCol>
                        </CRow>

                    </CCard>
                </div>
                <div className="row justify-content-md-center">
                    <CCard className="mb-3" style={{ maxWidth: '1320px', marginTop: "65px", borderRadius: "40px", background: "#F9FAFF" }}>
                        <CRow className="g-3">
                            <CCol md={6}>
                                <CCardImage orientation="top" src={visioImage} style={{ padding: '20px', objectFit: 'cover', maxHeight: '400px', borderRadius: "40px" }} />
                            </CCol>
                            <CCol md={6} >
                                <CCardBody>
                                    <CCardTitle style={{ textAlign: "center", marginBottom: "25px", marginTop: "50px" }}> Visión </CCardTitle>
                                    <CCardText style={{ textAlign: "justify" }}>
                                        {vision}
                                    </CCardText>
                                </CCardBody>
                            </CCol>
                        </CRow>
                    </CCard>
                </div>
                <div className="row justify-content-md-center">
                    <CCard className="mb-3" style={{ maxWidth: '1320px', marginTop: "65px", borderRadius: "40px", background: "#F9FAFF" }}>
                        <CRow className="g-3">
                            <CCol md={6}>
                                <CCardImage orientation="top" src={valoresImage} style={{ padding: '20px', objectFit: 'cover', maxHeight: '450px', borderRadius: "40px" }} />
                            </CCol>
                            <CCol md={6} >
                                <CCardBody>
                                    <CCardTitle style={{ textAlign: "center" }}> Valores </CCardTitle>
                                    <CCardText style={{ textAlign: "justify" }}>
                                        {valores}
                                    </CCardText>
                                </CCardBody>
                            </CCol>
                        </CRow>
                    </CCard>
                </div>
            </section>
            <section>
                <div style={{ backgroundColor: "#0065B3", marginTop: "100px" }}>
                    <div style={{ paddingTop: "50px", paddingLeft: "40px", paddingRight: "40px",paddingBottom:"100px" }} className="row justify-content-md-center">
                        <div style={{ marginTop: "15px" }}>
                            <h1 style={{ textAlign: "center", color: "white", fontSize: "50px" }}> Grupo Biomet </h1>
                        </div>
                        <div className="conteiner-caruosel">
                            <CCarousel controls indicators dark >
                                <CCarouselItem>
                                    <CImage className="d-block w-100 carosusel-img" src={group2}  alt="slide 2" />
                                    <CCarouselCaption className="carousel-caption d-none d-md-block" style={{ color: "white"}}>
                                        <h5>Día de la mujer y la niña en la ciencia" </h5>
                                        <p>Visita de estudiantes de la I.E. Mercedes Cabello </p>
                                    </CCarouselCaption>
                                </CCarouselItem>
                                <CCarouselItem>
                                    <CImage className="d-block w-100 carosusel-img" src={group3}  alt="slide 3" />
                                    <CCarouselCaption className="d-none d-md-block" style={{ color: "white" }}>
                                        <h5>Día de la mujer y la niña en la ciencia"</h5>
                                        <p>Experimentos con las alumnas de I.E. Mercedes Cabello</p>
                                    </CCarouselCaption>
                                </CCarouselItem>
                                <CCarouselItem>
                                    <CImage className="d-block w-100 carosusel-img" src={group1}  alt="slide 1" />
                                    <CCarouselCaption className="d-none d-md-block" style={{ color: "white" }}>
                                        <h5>Festival of Undergraduate Research and Creative Activities (FURCA)  </h5>
                                        <p>Proyecto desarrollado por el alumno de ing. Física Dari Acuña</p>
                                    </CCarouselCaption>
                                </CCarouselItem>
                                <CCarouselItem>
                                    <CImage className="d-block w-100 carosusel-img" src={group4}  alt="slide 3" />
                                    <CCarouselCaption className="d-none d-md-block" style={{ color: "white" }}>
                                        <h5>Expoferia Tecnologica UNI 2022</h5>
                                        <p>Paricipacion con 6 prototipos</p>
                                    </CCarouselCaption>
                                </CCarouselItem>
                                <CCarouselItem>
                                    <CImage className="d-block w-100 carosusel-img" src={group6}  alt="slide 3" />
                                    <CCarouselCaption className="d-none d-md-block" style={{ color: "white" }}>
                                        <h5>Expoferia Tecnologica UNI 2022</h5>
                                        <p>Prototipo Shaki Shaki </p>
                                    </CCarouselCaption>
                                </CCarouselItem>
                                <CCarouselItem>
                                    <CImage className="d-block w-100 carosusel-img" src={group7}  alt="slide 3" />
                                    <CCarouselCaption className="d-none d-md-block" style={{ color: "white" }}>
                                        <h5>Expoferia Tecnologica UNI 2022</h5>
                                        <p>Prototipo Rotator de Antena de dos grados de Libertad Apuntamiento autonomo </p>
                                    </CCarouselCaption>
                                </CCarouselItem>
                                <CCarouselItem>
                                    <CImage className="d-block w-100 carosusel-img" src={group5}  alt="slide 3" />
                                    <CCarouselCaption className="d-none d-md-block" style={{ color: "white" }}>
                                        <h5>Grupo Biomet - Raldep </h5>
                                        <p>"1er puesto en el Concurso de Proyectos Especiales 2023"  </p>
                                    </CCarouselCaption>
                                </CCarouselItem>
                            </CCarousel>
                        </div>
                    </div>
                </div>
            </section>
            <section style={{ padding: "100px" }} className="row justify-content-md-center">
                <CCard style={{ borderRadius: "50px", width: "900px", padding: "0px" }}>
                    <div style={{ position: "relative", borderRadius: "40px", overflow: "hidden" }}>
                        <CCardImage src={fila} style={{ objectFit: "cover", borderRadius: "40px", with: "600px", height: "400px" }} />
                        <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, background: "rgba(0, 101, 179, 0.5)" }}></div>
                        <CCardImageOverlay style={{ borderRadius: "40px", with: "900px" }} >
                            <CCardTitle style={{ color: "#fff", fontSize: "30px", textAlign: "center", marginTop: "240px" }}>Conoce Nuestros Proyectos</CCardTitle>
                            <CCardText className="ccard-text" style={{ textAlign: "center", color: "#fff" }}>
                                Nuestras producción científica involucran diversas carreras trabajando en conjunto para lograr un objetivo comun.
                            </CCardText>
                            <CCardText className="ccard-text-btm" style={{ textAlign: "center", marginBottom: "20px" }}  >
                                <a href="/produccion" className="btn produc-cienti" style={{ paddingLeft: "40px", paddingRight: "40px", borderRadius: "30px", background: "#0065B3", color: "white" }}> Nuestra producción Científica</a>
                            </CCardText>
                        </CCardImageOverlay>
                    </div>
                </CCard>
            </section>

        </div>
    );
}
export default AcercaDe; 
{/*


<div className="carousel-static">
<img
className="d-block w-100"
src={group1}
style={{
    width: "800px",
    height: "100%",
    objectFit: "contain",
    borderRadius: "40px",
}}
alt="static image"

        style={{ Width: "300px", Height: "400px", objectFit: "contain", borderRadius: "40px" }}
        style={{ justifyContent: "center", alignItems: "center", marginTop: "50px",height:"700px",maxWidth:"100%" }}
        style={{ textAlign: "center", marginBottom: "20px" }}
    />
</div>
    */}