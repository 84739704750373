import React from "react";
import { CCard, CCol, CCardImage, CCardBody, CCardTitle, CCardText, CRow, CCardImageOverlay } from "@coreui/react";
import areas from '../info/areas.json';
import { Link } from "react-router-dom";
import { useState } from "react";
import gea from'../image/areas/gea/gea.jpeg';
import instrumental from '../image/areas/instrumental/GRUPO INSTRUMENTAL 2.jpg';
import bioindicador from'../image/areas/bioindicadores/bioindicadores-biomarcadores.bmp';
import materiales2 from'../image/areas/materiales/CIENCIA DE MATERIALES 1.jpg';
import fila from '../image/biometfila/arcobiomet.jpeg'

function AreasDeInvestigacion() {

    const [hoveredItems, setHoveredItems] = useState([]);

    const imagenes = {
        "gea": gea,
        "materiales": materiales2,
        "instrumental": instrumental,
        "bioindicador": bioindicador,
    }

    const handleMouseEnter = (index) => {
      setHoveredItems((prevHoveredItems) => {
        const updatedHoveredItems = [...prevHoveredItems];
        updatedHoveredItems[index] = true;
        return updatedHoveredItems;
      });
    };
  
    const handleMouseLeave = (index) => {
      setHoveredItems((prevHoveredItems) => {
        const updatedHoveredItems = [...prevHoveredItems];
        updatedHoveredItems[index] = false;
        return updatedHoveredItems;
      });
    };
    return (
        <div>
            <section style={{padding:"50px"}}>
                <h1 style={{ textAlign: "center" }}> Áreas de Investigación</h1>
                <p>
                </p>
            </section>
            <section style={{ padding: "100px" ,background:"#0065B3"}}>
                <CRow xs={{ cols: 1, gutter: 3 }} md={{ cols: 2 }} >
                    {areas.map((item,index) => (
                       <div>
                        
                        <CCol xs key={index}>
                                <CCard style={{ borderRadius: '50px', margin: '40px',height:'680px',overflow:"hidden", transform: hoveredItems[index] ? 'scale(1.1)' : 'scale(1)', transition: 'transform 0.3s ease' }}  onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={() => handleMouseLeave(index)}>
                                    <Link to={item.ruta} className="btn" style={{ padding: "10px",height: '400px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                                        <CCardImage orientation="top" src={imagenes[item.imagen]} width="400"  height="400" style={{borderRadius:"40px",padding:"10px",objectFit: 'cover', maxHeight: '100%'}} />
                                    </Link>
                                    <CCardBody style={{padding:"20px"}}>
                                        <CCardTitle style={{ textAlign: "center"}}>
                                            <Link to={item.ruta}>
                                                {item.area}
                                            </Link>
                                        </CCardTitle>
                                        <CCardText style={{ fontSize:"14px",padding:"1px",textAlign: "justify",maxWidth:"100%"}}>
                                            {item.descripcion}
                                        </CCardText>
                                    </CCardBody>
                                    
                                </CCard>
                         </CCol>
                        
                    </div>
                    ))}
                </CRow>
            </section>
            <section style={{padding:"100px"}} className="row justify-content-md-center">
                <CCard  style={{borderRadius:"50px", width:"900px",padding:"0px"}}>
                    <div style={{ position: "relative", borderRadius: "40px", overflow: "hidden" }}>
                        <CCardImage src={fila}  style={{objectFit:"cover",borderRadius:"40px" ,with:"600px",height:"400px"}}/>
                        <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, background: "rgba(0, 101, 179, 0.5)" }}></div>
                        <CCardImageOverlay style={{borderRadius:"40px" ,with:"900px"}} >
                            <CCardTitle style={{color:"#fff",fontSize:"30px",textAlign:"center",marginTop:"240px"}}>Conoce a Nuestro Equipo </CCardTitle>
                            <CCardText className="ccard-text" style={{textAlign:"center",color:"#fff"}}>
                                Conformado por diferentes especialidades de ciencias. 
                            </CCardText>
                            <CCardText style={{textAlign:"center",marginBottom:"20px"}} >
                                <a href="/equipo" class="btn produc-cienti" style={{ paddingLeft: "40px", paddingRight: "40px", borderRadius: "30px",background:"#0065B3" ,color:"white" }}> Equipo y Colaboradores</a>
                            </CCardText>
                        </CCardImageOverlay>
                    </div>
                </CCard>
            </section>


        </div>
    )
}
export default AreasDeInvestigacion;