import React from "react";
import { CCard,CRow,CCol,CCardImage,CCardBody,CCardTitle,CCardText,CCardImageOverlay} from "@coreui/react";
import profeInvestigadores from '../info/profInvestigadores.json';
import invesAsociados from '../info/invesAsociados.json';
import becariosMaestria from '../info/becariosMaestria.json';
import tesistas from '../info/tesistas.json';
import pregrado from '../info/pregrado.json';
import yesenia from '../image/equipo/yesenia.jpg';
import estrada from '../image/equipo/estrada.jpg';
import castillo from '../image/equipo/castillo.jpg';
import mayta from '../image/equipo/mayta.jpg';
import ingrid from '../image/equipo/ingrid.jpg';
import aguilar from '../image/equipo/aguilar.jpg';
import huanca from '../image/equipo/huanca.jpg';
import cernadez from '../image/equipo/cernadez.jpeg';
import leonardo from '../image/equipo/leonardo.jpeg';
import fila from '../image/biometfila/arcobiomet.jpeg';
import alexander from '../image/equipo/Guillen Vasquez Alexander Gonzalo.jpg';
import foto from '../image/equipo/foto.jpg';
import elmer from '../image/equipo/elmer.jpeg';
import luis from '../image/equipo/luis.jpeg';
import piero from '../image/equipo/piero.jpeg';
import ana from '../image/equipo/sofia.jpeg';
import fabricio from '../image/equipo/fabricio.jpeg';
import egusquiza from '../image/equipo/egusquiza.jpeg';
import nelson from '../image/equipo/NELSON.png';
import patrick from '../image/equipo/Patrick .jpg';
import miguel from '../image/equipo/Miguel Tarazona.jpg';
import luisa from '../image/equipo/luisa.jpeg';
import cama from '../image/equipo/cama.jpeg';
import elizabeth from '../image/equipo/elizabeth.jpeg';
import roberto from '../image/equipo/roberto.jpeg';
import andre from '../image/equipo/andre.jpeg';
import jordan from '../image/equipo/jordan.jpeg';
import './AcercaDe.css';
function EquipoColabo(){

    const profesoresInvestigadores= {
        "estrada":estrada,
        "castillo":castillo,
        "mayta":mayta,
        "ingrid":ingrid,
        "elizabeth":elizabeth
    }
    const investigaAsociados={
        "aguilar":aguilar,
        "huanca":huanca,
        "cernadez":cernadez,
        "nelson":nelson,
        "luisa":luisa
    }
    const maestria={
        "leonardo":leonardo
    }
    const tesista={
        "steveen":foto,
        "elmer":elmer,
        "alexis":foto,
        "alexander":alexander,
        "andre":andre,
        "dari":foto
    }
    const alumno={
        "luis":luis,
        "fabricio":foto,
        "ana":foto,
        "viviana":foto,
        "gaus":foto,
        "piero":piero,
        "fabricio":fabricio,
        "ana": ana,
        "viviana":foto,
        "dari":foto,
        "cama":cama,
        "jordan":jordan,
        "patrick":patrick,
        "miguel":miguel,
        "angel":foto,
        "roberto":roberto,
        "egusquiza":egusquiza
    }
    return(
        <div >
            <section style={{textAlign:"center",paddingTop:"40px"}}>
                <h1> Equipo y Colaboradores </h1>
            </section>
            <section style={{paddingTop:"50px"}} className="row justify-content-md-center">
                <CCard className="mb-3" style={{ maxWidth: '1000px',borderRadius:"40px" }} >
                    <CRow className="g-0">
                        <CCol md={4}>
                            <CCardImage className="direc-img" src={yesenia} height={400} width={400} style={{borderRadius:"40px",padding:"20px"}}/>
                        </CCol>
                        <CCol md={8}>
                            <CCardBody>
                                <CCardTitle><h2>Pastrana Alta,Roxana Yesenia </h2> </CCardTitle>
                                <CCardText style={{color:"#aaa"}}>  <h5>Doctora en Ciencias química en el programa: Química.Universidad de Sao Pablo-Brasil.Docente Investigadora FC </h5>  </CCardText>
                                Email: <a href="mailto: rpastranaa@uni.edu.pe"> rpastranaa@uni.edu.pe </a>
                                <CCardText style={{textAlign:"justify"}}>
                                    Doctorado en la Universidad de São Paulo -São Paulo, Brasil (2016). Licenciado en Química (2010) por la Universidad de Nacional Ingeniería y Colegiada (2010). Especialista en Sistemas de Gestión de Calidad por la Universidad Nacional de Ingeniaría (2010). 
                                    El Doctorado Sándwiches con pasantía doctoral en la universidad de Toronto- Canadá(2015) y Lleida-España (2015).
                                    Con experiencia en Química Inorgánica, Química Orgánica, con énfasis en Química Bio-inorgánica Ambiental, principalmente en química Bio-inorganica Medicinal, desenvolvimiento de síntesis química de Péptidos mitocondriales, nucleares, actuando en terapias
                                    de quelación, síntesis de nuevos fármacos e indicadores marinos de toxicidad por metales y evaluación de estabilidad así como actividad redox activa. Especialista en bioindicadores y monitoreamiento de especies marinas así como de especies en cuerpos de 
                                    agua dulce. Análisista de estrés oxidativo y viabilidad celular por microscopia confocal/fluorescencia.
                                </CCardText>
                                <CCardText>
                                    <div >
                                        {/*<CButton style={{paddingLeft:"50px",paddingRight:"50px", borderRadius:"30px" }} > Ver Perfil </CButton>*/}
                                        <a  className="btn" href="https://orcid.org/0000-0003-4316-6572" style={{paddingLeft:"50px",paddingRight:"50px", borderRadius:"30px",background:"#0065B3" ,color:"white" }}>Ver Perfil</a>
                                    </div>
                                </CCardText>
                                
                            </CCardBody>
                        </CCol>
                    </CRow>
                </CCard>
            </section>
            <section style={{textAlign:"center",marginTop:"50px",marginBottom:"30px"}}>
                <h1> Investigadores Principales </h1>
            </section>
            <section style={{padding:"90px",background:"#0065B3"}}>
                <div className="d-flex flex-wrap flex-column align-items-center">
                    <CRow xs={{ cols: 1, gutter: 3 }} md={{ cols: 3 }} >
                        { profeInvestigadores.map ( item =>(
                            <CCol xs md={{cols:1}} key={item.nombre} style={{justifyContent: "center", alignItems: "center"}}>
                                <CCard style={{borderRadius:"40px",height:"96%",maxWidth:"360px",marginRight:"20px"}}>
                                    <CCardImage orientation="top" src={profesoresInvestigadores[item.imagen]} style={{borderRadius:"40px",height: "66%", objectFit: "cover",padding:"20px",justifyContent: "center", alignItems: "center" }}/>
                                    <CCardBody>
                                        <CCardTitle style={{fontSize:"18px"}}> {item.nombre} </CCardTitle>
                                        <CCardText style={{marginBottom:"0px"}} >
                                            {item.cargo}
                                        </CCardText>
                                        <CCardText style={{padding:"0px"}}>
                                                Email: {item.correo}
                                        </CCardText>
                                        <CCardText style={{textAlign:"center"}}>
                                                <div className="d-flex justify-content-center">
                                                    <a  className="btn" href={item.ctivitae} style={{paddingLeft:"50px",paddingRight:"50px", borderRadius:"30px",background:"#0065B3" ,color:"white",textAlign:"center" }}>Ver Perfil</a>
                                                </div>
                                        </CCardText>
                                        
                                    </CCardBody>
                                </CCard>
                            </CCol>

                        ))}
                    </CRow>
                </div>
            </section>
            <section>
                <h1 style={{textAlign:"center",marginTop:"20px",paddingBottom:"20px"}}> Investigadores Asociados </h1>
            </section>
            <section style={{padding:"90px",display: "flex", justifyContent: "center", alignItems: "center"}}>
                <CRow xs={{ cols: 1, gutter: 1 }} md={{ cols: 3 }}>
                    { invesAsociados.map ( item =>(
                        <CCol xs>
                            <CCard style={{borderRadius:"40px",height:"98%",maxWidth:"300px"}}>
                                <CCardImage orientation="top" src={investigaAsociados[item.imagen]} style={{borderRadius:"40px",height:"80%", objectFit:"cover",padding:"20px"}}/>
                                <CCardBody>
                                    <CCardTitle style={{fontSize:"18px"}}>{item.nombre} </CCardTitle>
                                    <CCardText style={{marginBottom:"1px"}}>
                                       {item.cargo}
                                    </CCardText>
                                    <CCardText style={{padding:"0px"}}>
                                       Email:{item.correo}
                                    </CCardText>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    ))}
                </CRow>
            </section>
            <section style={{textAlign:"center",marginTop:"30px"}}>
                <h1  > Becario de Maestría</h1>
            </section>
            <section style={{paddingTop:"50px",paddingBottom:"50px",background:"#0065B3",display: "flex", justifyContent: "center", alignItems: "center"}}>
                <CRow xs={{ cols: 1, gutter: 4 }} md={{ cols: 1 }}>
                    { becariosMaestria.map ( item =>(
                        <CCol xs>
                            <CCard style={{borderRadius:"40px",height:"100%" ,maxWidth:"300px"}}>
                                <CCardImage orientation="top" src={maestria[item.imagen]} style={{borderRadius:"40px",height:"65%", objectFit:"contain",padding:"20px",textAlign:"center"}}/>
                                <CCardBody>
                                    <CCardTitle style={{fontSize:"18px"}}>{item.nombre} </CCardTitle>
                                    <CCardText style={{marginBottom:"1px"}}>
                                       {item.cargo}
                                    </CCardText>
                                    <CCardText style={{padding:"0px"}}>
                                       Email:{item.correo}
                                    </CCardText>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    ))}
                </CRow>
            </section>
            <section>
                <h1 style={{textAlign:"center",paddingTop:"40px"}}> Tesistas </h1>
            </section>
            <section style={{padding:"90px",display: "flex", justifyContent: "center", alignItems: "center"}}>
                <CRow xs={{ cols: 1, gutter: 3 }} md={{ cols: 3 }} >
                    { tesistas.map ( item =>(
                        <CCol key={item.nombre}>
                            <CCard style={{borderRadius:"40px",height:"100%",maxWidth:"300px"}}>
                                <CCardImage orientation="top" src={tesista[item.imagen]} style={{borderRadius:"40px",height: "70%", objectFit: "cover",padding:"20px" }}/>
                                <CCardBody>
                                    <CCardTitle> {item.nombre} </CCardTitle>
                                    <CCardText style={{marginBottom:"1px"}} >
                                        {item.carrera}
                                    </CCardText>
                                    <CCardText style={{padding:"0px"}}>
                                        Email:{item.correo}
                                    </CCardText>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    ))}
                </CRow>
            </section>
            <section style={{textAlign:"center"}}>
                <h1 > Alumnos de Pregrado </h1>
            </section>
            <section style={{padding:"90px",display: "flex", justifyContent: "center", alignItems: "center",background:"#0065B3"}}>
                <CRow xs={{ cols: 1, gutter: 4 }} md={{ cols: 3 }} >
                    { pregrado.map ( item =>(
                        <CCol key={item.nombre}>
                            <CCard style={{borderRadius:"40px",height:"100%",maxWidth:"300px"}}>
                                <CCardImage orientation="top" src={alumno[item.imagen]} style={{borderRadius:"40px",height: "80%", objectFit: "cover",padding:"20px" }}/>
                                <CCardBody>
                                    <CCardTitle style={{fontSize:"18px"}}> {item.nombre} </CCardTitle>
                                    <CCardText style={{marginBottom:"1px"}} >
                                        {item.carrera}
                                    </CCardText>
                                    <CCardText style={{padding:"0px"}}>
                                        Email: {item.correo}
                                    </CCardText>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    ))}
                </CRow>
            </section>
            <section style={{padding:"100px"}} className="row justify-content-md-center">
                <CCard  style={{borderRadius:"50px", width:"900px",padding:"0px"}}>
                    <div style={{ position: "relative", borderRadius: "40px", overflow: "hidden" }}>
                        <CCardImage src={fila}  style={{objectFit:"cover",borderRadius:"40px" ,with:"600px",height:"400px"}}/>
                        <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, background: "rgba(0, 101, 179, 0.5)" }}></div>
                        <CCardImageOverlay style={{borderRadius:"40px" ,with:"900px"}} >
                            <CCardTitle style={{color:"#fff",fontSize:"30px",textAlign:"center",marginTop:"240px"}}>Conoce Nuestros Áreas de Investigación</CCardTitle>
                            <CCardText className="ccard-text" style={{textAlign:"center",color:"#fff"}}>
                                Nuestras producción científica involucran diversas carreras trabajando en conjunto para lograr un objetivo comun.
                            </CCardText>
                            <CCardText style={{textAlign:"center",marginBottom:"20px"}} >
                                <a href="/produccion" class="btn produc-cienti" style={{ paddingLeft: "40px", paddingRight: "40px", borderRadius: "30px",background:"#0065B3" ,color:"white" }}> Nuestras Areas de Investigacion</a>
                            </CCardText>
                        </CCardImageOverlay>
                    </div>
                </CCard>
            </section>
 
        </div>
    )
}
export default EquipoColabo;