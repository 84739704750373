import React from "react";
import { CCol, CRow,CFooter,CContainer, CImage, CButton } from "@coreui/react";
import logo from '../image/logo/logo.jpg';
import  CIcon  from '@coreui/icons-react';
import {cibFacebookF,cibInstagram,cilInstitution,cilLocationPin,cilAt,cibWhatsapp} from '@coreui/icons';



function Footer(){
    return(
        <div >
            <CFooter style={{background:"#0065B3"}}>
                <CContainer  style={{paddingTop:"40px"}}>
                    <CRow className="row justify-content-md-center" >
                        <CCol xl={4} style={{paddingRight:"100px"}}>
                            <CImage src={logo} alt="logobiomet" width={150} />
                            <div style={{color:"white",fontSize:"12px"}}>
                                <p>"Convergiendo ciencia, ingeniería y computación, ¡desvelamos los secretos del universo!"</p>
                            </div>
                        </CCol>
                        <CCol xl={4}>
                            <h1 style={{fontSize:"30px",color:"white"}}> Servicios</h1>
                            <div style={{ color: "white", fontSize: "10px" }}>
                                <p><CIcon icon={cilInstitution} size="sm" style={{ marginRight: "10px" }} />Universidad Nacional de Ingenieria - Facultad de Ciencias</p>
                                <p><CIcon icon={cilLocationPin} size="sm" style={{ marginRight: "10px" }} />Avenida tupac Amaru 210, Rimac - Puerta N° 5</p>
                                <p><CIcon icon={cibWhatsapp} size="sm" style={{ marginRight: "10px" }} />979266780</p>
                                <p><CIcon icon={cilAt} size="sm" style={{ marginRight: "10px" }} /><a href="mailto:rpastranaa@uni.edu.pe" style={{ color: "white" }}>rpastranaa@uni.edu.pe</a></p>
                            </div>
                        </CCol>
                        <CCol xl={4}>
                            <div>
                                <h1 style={{color:"white",fontSize:"30px"}}> Nuestras Redes sociales</h1>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <CButton href="https://www.facebook.com/biometgroup" style={{background:"#0065B3",borderColor:"#0065B3"}}>
                                        <CIcon icon={cibFacebookF}  size="xl" style={{ margin: "10px" }} />        
                                    </CButton>
                                    <CButton style={{background:"#0065B3",borderColor:"#0065B3"}} href="https://www.instagram.com/biomet_lab/">
                                        <CIcon icon={cibInstagram} size="xl" style={{ margin: "10px" }} />
                                    </CButton>
                                </div>
                            </div>
                        </CCol>
                    </CRow>
                </CContainer>
            </CFooter>
        </div>
    );
}
export default Footer;
