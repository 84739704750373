import React from "react";
import directora from '../info/directora.json';
import { CCard,CCol,CCardBody,CCardImage,CCardText } from "@coreui/react";
import direc from '../image/equipo/yesenia.jpg';
function Directora (){
    
    return(
        <div>
            <section style={{textAlign:"center",marginTop:"50px"}}>
                <h1> Roxana Yesenia Pastrana Alta </h1>
                <h4> Directora, Laboratorio Biomet </h4>
                <h4> Doctora en Ciencias quimicas </h4>
            </section>
            <section  style={{ display: "flex",marginRight:"50px" }}>
                <CCol xs="xs" >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "70vh" , paddingLeft:"500px" }}>
                        <CCard style={{ borderRadius: "0px", height: "60%", width: "500px", position: "fixed"}}>
                            <CCardImage orientation="top" src={direc} style={{ borderRadius: "0px", padding: "20px", height: "100%" }} />
                            <CCardBody>
                                <CCardText>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <a href="#" className="btn " style={{ borderRadius: "30px", marginTop: "10px" ,background:"#0065B3" ,color:"white" }}>Ver Perfil</a>
                                    </div>
                                </CCardText>
                            </CCardBody>
                        </CCard>
                    </div>
                </CCol> 
                <CCol style={{ marginLeft: "250px",position:"relative", zIndex:1 }}>
                    {directora.map(item => (
                        <div style={{ marginTop: "100px", marginLeft: "30px" }}>
                            <h1>Educación</h1>
                            <h5> {item.educacion}</h5>
                            <h1>Experiencia Profesional</h1>
                            {item.experiencia.map(itemExp => (
                                <>
                                <h4>{itemExp.Fecha}</h4>
                                <h4>{itemExp.cargo}</h4>
                                </>
                            ))}
                            <p>{item.acercade}</p>
                        </div>
                    ))}
                </CCol>
            </section>
        </div>
    )
}
export default Directora;