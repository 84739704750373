import React from 'react';
import { CCard, CCardBody,CCardImage,CCardImageOverlay, CCardTitle, CCardText } from '@coreui/react';
import patentes from '../info/patentes.json';
import fila from '../image/biometfila/arcobiomet.jpeg';
import './AcercaDe.css';
function Patentes(){
    return( 
        <div>
            <section>
                <h1 style={{textAlign:"center",color:"#0065B3"}}>Patentes</h1>
                {patentes.map( item => (
                    <div >
                        <h1>{item.anio} </h1>
                        <CCard  style={{margin:"80px"}}>
                            <CCardBody >
                                <CCardTitle>
                                    {item.titulo}
                                </CCardTitle>
                                <CCardText>
                                    {item.numero}
                                </CCardText>
                            </CCardBody>    
                        </CCard>
                    </div>
                ))}
            </section>
            <section style={{padding:"100px"}} className="row justify-content-md-center">
                <CCard  style={{borderRadius:"50px", width:"900px",padding:"0px"}}>
                    <div style={{ position: "relative", borderRadius: "40px", overflow: "hidden" }}>
                        <CCardImage src={fila}  style={{objectFit:"cover",borderRadius:"40px" ,with:"600px",height:"400px"}}/>
                        <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, background: "rgba(0, 101, 179, 0.5)" }}></div>
                        <CCardImageOverlay style={{borderRadius:"40px" ,with:"900px"}} >
                            <CCardTitle style={{color:"#fff",fontSize:"30px",textAlign:"center",marginTop:"240px"}}>Conoce Nuestras Áreas de Investigación </CCardTitle>
                            <CCardText className="ccard-text" style={{textAlign:"center",color:"#fff"}}>
                                Nuestras producción científica involucran diversas carreras trabajando en conjunto para lograr un objetivo comun.
                            </CCardText>
                            <CCardText style={{textAlign:"center",marginBottom:"20px"}} >
                                <a href="/produccion" class="btn produc-cienti" style={{ paddingLeft: "40px", paddingRight: "40px", borderRadius: "30px",background:"#0065B3" ,color:"white" }}> Areas de Investigacion</a>
                            </CCardText>
                        </CCardImageOverlay>
                    </div>
                </CCard>
            </section>
            {/*
            <section>
                <div style={{ maxWidth:"1000px",display:"flex", justifyContent: "center", alignItems: "center", marginTop: "70px" }}>
                    <CCarousel controls indicators dark  >
                        <CCarouselItem>
                            <CImage className="d-block w-100" src={patente1} style={{ Width:"400px",Height:"400px",objectFit:"cover",borderRadius: "40px" }} alt="slide 2" />
                            <CCarouselCaption className="d-none d-md-block" style={{color:"black"}}>
                                <h5>Primera Patente </h5>
                            </CCarouselCaption>
                        </CCarouselItem>
                        <CCarouselItem>
                            <CImage className="d-block w-100" src={patente2} style={{ Width:"400px",Height:"400px",objectFit:"cover",borderRadius: "40px" }} alt="slide 3" />
                            <CCarouselCaption className="d-none d-md-block" style={{color:"black"}}>
                                <h5>Segunda Patente</h5>
                            </CCarouselCaption>
                        </CCarouselItem>
                        <CCarouselItem>
                            <CImage className="d-block w-100" src={patente3}  style={{ Width:"400px",Height:"400px%",objectFit:"cover",borderRadius: "40px" }} alt="slide 1" />
                            <CCarouselCaption className="d-none d-md-block" style={{color:"black"}}>
                                <h5>Tercera Patente</h5>
                            </CCarouselCaption>
                        </CCarouselItem>
                        <CCarouselItem>
                            <CImage className="d-block w-100" src={patente4}  style={{ Width:"400px",Height:"400px%",objectFit:"cover",borderRadius: "40px" }} alt="slide 1" />
                            <CCarouselCaption className="d-none d-md-block" style={{color:"black"}}>
                                <h5>Cuarta Patente</h5>
                            </CCarouselCaption>
                        </CCarouselItem>
                    </CCarousel>
                </div>
            </section>
                */}
        </div>   
    )
}
export default Patentes;
