
import React from "react";
import { CImage, CListGroup,CListGroupItem,CCarousel,CCarouselItem,CCarouselCaption,CCard,CCardImage,CCardImageOverlay,CCardTitle,CCardText } from "@coreui/react";
import fila from '../image/biometfila/arcobiomet.jpeg';
import portada from '../image/areas/gea/portada.jpeg';
import data from '../info/geaActiv.json';
import actividad1 from '../image/areas/gea/actividades/cansat.jpeg';
import actividad2 from '../image/areas/gea/actividades/cansat2.jpeg';
import actividad3 from '../image/areas/gea/actividades/cansat3.jpeg';
import actividad4 from '../image/areas/gea/actividades/globo.jpeg';
import actividad5 from '../image/areas/gea/actividades/globo2.jpeg';
import actividad6 from '../image/areas/gea/actividades/globo3.jpeg';
import dataCarrusel from '../info/geaCarru.json';
import './AcercaDe.css';
function Gea(){
    const geaImg={
        "acti1":actividad1,
        "acti2":actividad2,
        "acti3":actividad3,
        "acti4":actividad4,
        "acti5":actividad5,
        "acti6":actividad6,
        
    }
    return(
        <div>
            <section style={{textAlign:"center"}}>
                <h1 style={{color:"#0065B3",paddingBottom:"30px"}}> Aerospace Technologies Research Group (GEA) </h1>
            </section>
            <section style={{padding:"20px",marginBottom:"30px"}}>
                <div style={{ display:"flex" ,justifyContent:"center",borderRadius:"20px" }}>
                    <CImage className="img-gea" src={portada} style={{borderRadius:"30px",maxWidth:"800px",maxHeight:"800px"}} alt="portada Gea" />
                </div>
            </section>
            <section style={{ textAlign: "center",paddingBottom:"40px",background:"#fff",background:"#0065B3",paddingTop:"20px" }}>
                <h1 style={{marginTop:"20px",color:"white",paddingBottom:"25px"}}> Proyectos  </h1>
                <div className="d-flex justify-content-center" style={{ display:"flex", justifyContent:"center", marginTop:"20px"}} >
                    <CListGroup style={{borderRadius:"30px"}}>
                        {data.map( (item) => (
                                <CListGroupItem component="a" href="/proyectos" style={{maxWidth:"1400px",with:"100%",padding:"10px"}}>
                                    <div className="d-flex w-100 justify-content-between" style={{margin:"20px",padding:"20px"}}>
                                        <h5 className="mb-1" style={{color:"#0065B3",fontSize:"25px"}}>{item.titulo}</h5>
                                    </div>
                                    <strong style={{fontSize:"20px"}}> {item.estado}</strong>                                        
                                </CListGroupItem>
                        ))}
                    </CListGroup>
                </div>
            </section>
            <section style={{ textAlign: "center", padding:"30px",background:"#0065B3",paddingBottom:"50px",height:"900px" }}>
                <h1 style={{color:"white"}}> Actividades </h1>
                <div className="d-flex justify-content-center" style={{marginTop:"30px",borderRadius:"20px"}}>
                    <CCarousel controls indicators style={{ width: "800px",borderRadius:"20px" }}>
                        {dataCarrusel.map((item)=>(
                            <CCarouselItem>
                                <CImage className="d-block w-100 carosusel-img" src={geaImg[item.imagen]} alt="slide 1" style={{borderRadius:"20px", maxWidth:"800px"}} />
                                <CCarouselCaption className="d-none d-md-block">
                                    <h5>{item.titulo}</h5>
                                </CCarouselCaption>
                            </CCarouselItem>
                        ))}
                    </CCarousel>
                </div>
            </section>
            <section style={{padding:"100px"}} className="row justify-content-md-center">
                <CCard  style={{borderRadius:"50px", width:"900px",padding:"0px"}}>
                    <div style={{ position: "relative", borderRadius: "40px", overflow: "hidden" }}>
                        <CCardImage src={fila}  style={{objectFit:"cover",borderRadius:"40px" ,with:"600px",height:"400px"}}/>
                        <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, background: "rgba(0, 101, 179, 0.5)" }}></div>
                        <CCardImageOverlay style={{borderRadius:"40px" ,with:"900px"}} >
                            <CCardTitle style={{color:"#fff",fontSize:"30px",textAlign:"center",marginTop:"240px"}}>Conoce a Nuestro Equipo</CCardTitle>
                            <CCardText className="ccard-text" style={{textAlign:"center",color:"#fff"}}>
                                Un equipo conformado por diferentes especialidades, realizando ciencia .
                            </CCardText>
                            <CCardText style={{textAlign:"center",marginBottom:"20px"}} >
                                <a href="/equipo" class="btn produc-cienti" style={{ paddingLeft: "40px", paddingRight: "40px", borderRadius: "30px",background:"#0065B3" ,color:"white" }}> Equipo y Colaboradores</a>
                            </CCardText>
                        </CCardImageOverlay>
                    </div>
                </CCard>
            </section>
        </div>
    )
}
export default Gea;