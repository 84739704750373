import React from "react";
import { CCard,CCardImage,CCardTitle,CCardText,CCardImageOverlay } from "@coreui/react";
import { Link } from "react-router-dom";
import { useState } from "react";
import fila from '../image/biometfila/arcobiomet.jpeg';
import publicaciones from'../image/publicaciones/publicaciones.jpg';
import proyectos from '../image/proyectos/proyectos.jpg';
import patente from '../image/patentes/patente1.jpg';
import './AcercaDe.css'

function ProduccionCientifica() {
    const [hoveredItems, setHoveredItems] = useState([]);

    const handleMouseEnter = (index) => {
      setHoveredItems((prevHoveredItems) => {
        const updatedHoveredItems = [...prevHoveredItems];
        updatedHoveredItems[index] = true;
        return updatedHoveredItems;
      });
    };
  
    const handleMouseLeave = (index) => {
      setHoveredItems((prevHoveredItems) => {
        const updatedHoveredItems = [...prevHoveredItems];
        updatedHoveredItems[index] = false;
        return updatedHoveredItems;
      });
    };

    return (
        <div>
            {/* Esta seccion va el titulo y una pequeña descripcion */}
            <section id="titulo-Produccion cientifica" style={{ paddingBottom: "100px", textAlign: "center" }}>
                <strong style={{ color: "#0065B3", fontSize: "45px" }} > Producción Científica </strong>
                <div style={{ maxWidth: "1700px", margin: "0 auto" }}>
                    <p style={{ marginLeft: "80px", marginRight: "100px", marginTop: "50px", textAlign: "justify" }}>
                    Nuestro trabajo esta dedicado a llevar a cabo investigaciones innovadoras en sus respectivas áreas de especialización. Trabajando de manera colaborativa y multidisciplinaria, generan conocimiento y contribuyen al avance científico y tecnológico.
                    </p>
                </div>
            </section>
            {/* Esta area se divide en 3 partes acorde al tipo de publicacion */}
            <section id="categ-publicaciones" style={{ backgroundColor: "#0065B3" }}>
                <div class="row row-cols-1 row-cols-md-3 g-4" style={{ padding: "80px" }}>
                    <div class="col"  style={{transform: hoveredItems[0] ? 'scale(1.1)' : 'scale(1)', transition: 'transform 0.3s ease' }}  onMouseEnter={() => handleMouseEnter(0)} onMouseLeave={() => handleMouseLeave(0)} >
                        <Link to="/publicaciones" className="btn" style={{ padding: "30px", background: "#0065B3" }}>
                            <div className="card h-100" style={{borderRadius:"40px"}}>
                                <img src={publicaciones} class="card-img-top" alt="Publicaciones"  style={{padding:"20px", borderRadius:"40px", height:"400px",objectFit:"cover"}}/>
                                <div className="card-body">
                                    {/*<h5 class="card-title" style={{textAlign:"center", fontSize:"25px", color:"#0065B3"}}>Publicaciones</h5> */}
                                    <Link to="/publicaciones" class="card-title" style={{ textAlign: "center", fontSize: "25px", color: "#0065B3" }}> Publicaciones </Link>
                                </div>
                            </div>
                        </Link> 
                    </div>
                    <div class="col" style={{transform: hoveredItems[1] ? 'scale(1.1)' : 'scale(1)', transition: 'transform 0.3s ease' }}  onMouseEnter={() => handleMouseEnter(1)} onMouseLeave={() => handleMouseLeave(1)}>
                        <Link to="/patentes" className="btn" style={{ padding: "30px", background: "#0065B3" }}>
                            <div class="card h-100" style={{borderRadius:"40px"}}>
                                <img src={patente} class="card-img-top" alt="Patentes"  style={{padding:"20px", height:"400px",objectFit:"cover"}}/>
                                <div className="card-body" >
                                    <Link to="/patentes" class="card-title" style={{ textAlign: "center", fontSize: "25px", color: "#0065B3" }}> Patentes </Link>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div class="col" style={{transform: hoveredItems[2] ? 'scale(1.1)' : 'scale(1)', transition: 'transform 0.3s ease' }}  onMouseEnter={() => handleMouseEnter(2)} onMouseLeave={() => handleMouseLeave(2)}>
                        <Link to="/proyectos" className="btn" style={{ padding: "30px", background: "#0065B3" }}>
                            <div className="card h-100" style={{borderRadius:"40px"}}>
                                <img src={proyectos} class="card-img-top" alt="Proyectos" style={{padding:"20px", height:"400px",objectFit:"cover"}} />
                                <div className="card-body">
                                    <Link to="/proyectos" class="card-title" style={{ textAlign: "center", fontSize: "25px", color: "#0065B3" }}> Proyectos </Link>

                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </section>
            <section style={{padding:"100px"}} className="row justify-content-md-center">
                <CCard  style={{borderRadius:"50px", width:"900px",padding:"0px"}}>
                    <div style={{ position: "relative", borderRadius: "40px", overflow: "hidden" }}>
                        <CCardImage src={fila}  style={{objectFit:"cover",borderRadius:"40px" ,with:"600px",height:"400px"}}/>
                        <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, background: "rgba(0, 101, 179, 0.5)" }}></div>
                        <CCardImageOverlay style={{borderRadius:"40px" ,with:"900px"}} >
                            <CCardTitle style={{color:"#fff",fontSize:"30px",textAlign:"center",marginTop:"240px"}}>Conoce Nuestras Áreas de Investigación </CCardTitle>
                            <CCardText className="ccard-text" style={{textAlign:"center",color:"#fff"}}>
                                Nuestras producción científica involucran diversas carreras trabajando en conjunto para lograr un objetivo comun.
                            </CCardText>
                            <CCardText style={{textAlign:"center",marginBottom:"40px"}} >
                                <a href="/areas" class="btn produc-cienti" style={{ paddingLeft: "40px", paddingRight: "40px", borderRadius: "30px",background:"#0065B3" ,color:"white" }}> Areas de Investigacion</a>
                            </CCardText>
                        </CCardImageOverlay>
                    </div>
                </CCard>
            </section>

        </div>
    );
}
export default ProduccionCientifica;