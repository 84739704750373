import React from 'react';
import AcercaDe from './components/AcercaDe';
import '@coreui/react';
import NavBar from './components/NavBar';
import ProduccionCientifica from './components/ProduccionCientifica';
import Publicaciones from './components/Publicaciones';
import { BrowserRouter, Route, Routes } from 'react-router-dom'; 
import AreasDeInvestigacion from './components/AreasDeInvestigacion';
import EquipoColabo from './components/EquipoColabo';
import Patentes from './components/Patentes';
import Proyectos from './components/Proyectos';
import Investigador1 from './components/Investigador1';
import Investigador2 from './components/Investigador2';
import Investigador3 from './components/Investigador3';
import Directora from './components/Directora';
import Footer from './components/Footer';
import Gea from './components/Gea';
import Materiales from './components/Materiales';
import Instrumentacion from './components/Instrumentacion';
import Bioindicadores from './components/Bioindicadores';

function App() {
  return (
      <BrowserRouter>
        <NavBar/>
        <div style={{ marginTop:"150px"}}>
          <Routes>
            <Route path='/' element={ <AcercaDe/> }/>
            <Route path='/produccion' element={ <ProduccionCientifica/> }/>
            <Route path='/areas' element={ <AreasDeInvestigacion/>}/>
            <Route path='/equipo' element={ <EquipoColabo/>}/>
            <Route path='/publicaciones' element={<Publicaciones/>}/>
            <Route path='/patentes' element={<Patentes/>}/>
            <Route path='/proyectos' element={<Proyectos/>}/>
            <Route path='/investigador1' element={<Investigador1/>}></Route>
            <Route path='/investigador2' element={<Investigador2/>}></Route>
            <Route path='/investigador3' element={<Investigador3/>}></Route>
            <Route path='/directora' element={<Directora/>}></Route>
            <Route path='/gea' element={<Gea/>}></Route>
            <Route path='/materiales' element={<Materiales/>}></Route>
            <Route path='/instrumentacion' element={<Instrumentacion/>}></Route>
            <Route path='/bioindicadores' element={<Bioindicadores/>}></Route>
          </Routes>
        <Footer/>
        </div>
      </BrowserRouter>
    
  );
}

export default App;
