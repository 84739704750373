import React from "react";
import { CCard,CCardBody,CCardText,CCardImage,CCol } from "@coreui/react";
import investigador3 from '../info/investigador3.json';
import vision from '../image/acercaDe/vision.png';
function Investigador3(){

    return(
        <section  style={{ display: "flex",marginRight:"50px" }}>
        <CCol xs="xs" >
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "50vh" , paddingLeft:"500px" }}>
                <CCard style={{ borderRadius: "40px", height: "50vh", width: "300px", position: "fixed"}}>
                    <CCardImage orientation="top" src={vision} style={{ borderRadius: "40px", padding: "20px", height: "80%" }} />
                    <CCardBody>
                    <CCardText>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <a href="#" className="btn " style={{ borderRadius: "40px", marginTop: "20px" ,background:"#0065B3" ,color:"white" }}>Ver CTI VITAE</a>
                        </div>
                    </CCardText>
                    </CCardBody>
                </CCard>
            </div>
        </CCol> 
        <CCol style={{ marginLeft: "250px",position:"relative", zIndex:1 }}>
            <div style={{ textAlign: "center", marginTop: "50px" }}>
                <h1>Investigador 3</h1>
                <p>Investigador Asociado senior</p>
            </div>
            {investigador3.map(item => (
                <div style={{ marginTop: "100px", marginLeft: "30px" }}>
                    <h1>Acerca de {item.nombre}</h1>
                    <h2>{item.especialidad}</h2>
                    <p>{item.acercade}</p>
                </div>
            ))}
        </CCol>
        
    </section>
    )
}
export default Investigador3;